import { GoldIco, XPIco } from "../../../../layout/components/HeaderFarm/components/ResourceCard";
import { UpgradeArrow, UpgradeWindow } from "../../../Room";
import { PopupButton } from "../../../../components/PopupButton";
import { HandleBackButton } from "../../../../layout/components/HeaderCave/components";
import { useTranslation } from "react-i18next";
import { HeaderCave } from "../../../../layout/components/HeaderCave";
import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ReactFlow, Node, Edge } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useSessionStorage, useWindowSize } from "@uidotdev/usehooks";
import { GenerateNodesAndEdges, HeroInfoModal, HeroTierModal, transformTreeToSkillsData } from "./components";
import { SkillData } from "./components/transformTreeToSkillsData";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { Tree, EBranch, BonusType, Levels } from "../../../../interfaces/hero";
import { Resources } from "../../../../enums/resources";
import { getHeroesShards, getHeroTierUpgradePrice, updateHeroLevel } from "../../../../endpoints/heroEndpoints";
import { useTelegram } from "../../../../hooks/useTelegram";
import { getBalance } from "../../../../endpoints/farmMock";
import { CardProps } from "../../../../interfaces/card";
import { HeroRating } from "../HeroRating";
import { TutorialFarmSave, TutorialFarmStage } from "../../../../interfaces/tutorial";
import { saveFarmTutorialProgress } from "../../../../app/features/farmTutoralSlice";
import { Offer } from "../../../../pages/Shop/components";
import { usePurchase } from "../../../../hooks/usePurchase";
import { defineCountString } from "../../../../utils/farmConfigParser";
import { OfferResourceProps } from "../../../../pages/Shop/components/OfferResources";
import { OfferProps } from "../../../../pages/Shop/components/Offer";
import { ConfigOffer, OfferType } from "../../../../mock/offers";
import { PurchaseModal } from "../../../../components/PurchaseModal";
import { PointerHand } from "../../../../components/PointerHand";
import { TutorialTooltip } from "../../../../components/TutorialTooltip";
import { SuccessOverlay } from "../../../../components/SuccessOverlay";
import { useSoundService } from "../../../../utils/soundService";
import { getIsSelected } from "../../../../pages/Shop";
import { CategoryItem, HeroesItem } from "../../../Inventory";
import { HeroShard, ItemType, UniversalShard } from "../../../../interfaces/lootBotx";
import { heroShardMap, universalShardMap } from "../../../../utils/mapping";

interface HeroSkills {
  label: string;
  nowValue: number;
  upgradeValue: number;
}

interface HeroCardProps {
  id: number;
  img: string;
  rating: { claimedLevels: number; totalLevels: number }; // Number of full stars
  rarity: string;
  totalStars?: number; // Total number of stars (default is 5)
  name: string;
  level: number;
  description: string;
  heroData: HeroSkills[];
  handlePrevious: () => void;
  handleNext: () => void;
  handleClose: () => void;
  treesData: Tree[];
  skills: Levels[];
  health: number;
  mana: number;
  manaType: number;
  price: number;
  nextLevel: Levels | null;
  upgradeCards: number;
  getHeroesList: () => void;
  cardsAmount: number;
  cards: CardProps[];
  maxLevel: number;
  hasLevelUp: boolean;
}

export const HeroCard = (props: HeroCardProps) => {
  const {
    maxLevel,
    cards,
    getHeroesList,
    upgradeCards,
    nextLevel,
    cardsAmount,
    id,
    img,
    rating,
    rarity,
    totalStars = 5,
    heroData,
    name,
    level,
    description,
    handlePrevious,
    handleNext,
    handleClose,
    treesData,
    skills,
    health,
    mana,
    manaType,
    price,
    hasLevelUp,
  } = props;

  // console.log("max lvl", maxLevel);

  const { userId } = useTelegram();

  const { playSound } = useSoundService();

  const resources = useSelector((state: RootState) => state.resources.resources);

  const currentValue = resources.find((v) => v.resourceType === Resources.experience)!.value;

  const { t } = useTranslation();
  const backgroundHeroes = require(`../../../../assets/images/heroes/backgrounds/hero-${id}.jpg`);

  const [isShowHeroInfo, setIsShowHeroInfo] = useState(false);
  const [isShowHeroTier, setIsShowHeroTier] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [openHeroTierModalSession, setOpenHeroTierModalSession] = useSessionStorage("openHeroTierModal", false);

  const variants = {
    hidden: { opacity: 0, y: 50 }, // Початковий стан: прозорість 0, зміщення вниз
    visible: { opacity: 1, y: 0 }, // Кінцевий стан: прозорість 1, без зміщення
    exit: { opacity: 0, y: 0 }, // Вихідний стан: прозорість 0, зміщення вниз
  };

  const dispatchSave = useDispatch<AppDispatch>();

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const updateSave = (save: TutorialFarmSave) => {
    dispatchSave(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  useEffect(() => {
    if (openHeroTierModalSession) {
      setIsShowHeroTier(true);
    }
    setOpenHeroTierModalSession(false);
  }, []);

  const handleLevelUp = async () => {
    playSound("heroLevelUp");

    const result = await updateHeroLevel({
      clientId: userId,
      heroId: id,
    });

    await getBalance({
      clientId: userId,
    });

    if (result) {
      setIsVisible(true);

      getHeroesList();
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
      if (farmTutorialSave?.stage === TutorialFarmStage.clickOnHero) {
        if (farmTutorialSave?.stage === TutorialFarmStage.clickOnHero) {
          updateSave({
            stage: TutorialFarmStage.upgradeHero,
          });
        }
      }
    }
  };

  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    offers: { variables: allOffers },
  } = appConfig;

  const heroStoreOffer = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.experience && offer.offerId.value === 6
  );

  const heroKeysOffer = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.keysOffer && offer.offerId.value === 8
  );

  const mappedHeroStoreOffer: OfferProps[] = heroStoreOffer.map((offer: ConfigOffer): OfferProps => {
    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: "vertical",
        amount: defineCountString(item.amount.value),
      };
    });
    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      type: "gold",
      label: "full_top",
      layout: "full",
      price: defineCountString(offer.stars.value),
      deadline: "January 31, 2025",
    };
  });

  const mappedHeroKeysOffer: OfferProps[] = heroKeysOffer.map((offer: ConfigOffer): OfferProps => {
    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: "vertical",
        amount: defineCountString(item.amount.value),
      };
    });

    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      type: "gold",
      label: "full_top",
      layout: "full",
      price: defineCountString(offer.stars.value),
      deadline: "January 31, 2025",
    };
  });
  const offer = mappedHeroStoreOffer[0];
  const offerKeys = mappedHeroKeysOffer[0];

  const [showOffer, setShowOffer] = useState(false);
  const handleNoExpPromoteButPack = () => {
    setShowOffer(true);
  };

  const [hasTierUp, setHasTierUp] = useState(false);
  const [universalShards, setUniversalShards] = useState<HeroesItem[]>([]);

  const fetchUniversalShards = async () => {
    try {
      const responseHeroesShards = await getHeroesShards({
        clientId: userId,
      });

      const mappedUniversalShards: HeroesItem[] = responseHeroesShards.universalShards.map((item, index) => {
        return {
          id: `universalShards-${item.shardId}-${index}`,
          type: ItemType.universalShard,
          name: universalShardMap[item.shardId as UniversalShard].name,
          description: universalShardMap[item.shardId as UniversalShard].description,
          category: CategoryItem.Heroes,
          amount: item.amount,
          icon: universalShardMap[item.shardId as UniversalShard].image,
          rarity: item.shardId,
        };
      });

      if (mappedUniversalShards) {
        setUniversalShards(mappedUniversalShards);
        return mappedUniversalShards.find((shard) => shard.rarity === 0)!.amount;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHeroTierUpgradePrice = async () => {
    try {
      const price = await getHeroTierUpgradePrice({
        clientId: userId,
        heroId: id,
      });

      return price;
    } catch (error) {}
  };

  useEffect(() => {
    const hasTierUpgrade = async () => {
      const universalShards = await fetchUniversalShards();
      const tierUpgradePrice = await fetchHeroTierUpgradePrice();
      const allTiersUpgrade: boolean[] = [];

      if (tierUpgradePrice) {
        treesData.forEach((node: any) => allTiersUpgrade.push(node.isClaimed));
        setHasTierUp(
          (upgradeCards > tierUpgradePrice || universalShards! + upgradeCards > tierUpgradePrice) &&
            !allTiersUpgrade.every((isClaimed) => isClaimed)
        );
      }
    };

    if (treesData.length > 0) {
      hasTierUpgrade();
    }
  }, [treesData]);

  return (
    <>
      {/* Dark bg for clickOnHero, clickOnRankUpgrade, chooseTierToUpgrade */}
      {(farmTutorialSave?.stage === TutorialFarmStage.clickOnHero ||
        farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade ||
        farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade) && (
        <div
          onClick={() => {
            if (farmTutorialSave?.stage === TutorialFarmStage.clickOnHero) {
              updateSave({
                stage: TutorialFarmStage.upgradeHero,
              });
            }
          }}
          className="absolute inset-0 bg-black bg-opacity-50 z-[11]"
        ></div>
      )}
      {!isShowHeroTier && (
        <div
          className={`absolute w-full ${
            farmTutorialSave?.stage === TutorialFarmStage.clickOnHero ? "z-[5]" : "z-10"
          } top-0`}
        >
          <HeaderCave
            cards={cards}
            pageName={level >= maxLevel ? `${name} Lvl ${level} MAX` : `${name} Lvl ${level}`}
            onClick={handleClose}
          />
        </div>
      )}

      <img className={`w-full absolute left-0 ${isShowHeroTier ? "top-0" : "top-[30px]"}`} src={backgroundHeroes}></img>

      {isShowHeroInfo && <HeroInfoModal skills={skills} onClose={() => setIsShowHeroInfo(false)} />}

      {isShowHeroTier && (
        <HeroTierModal
          getHeroesList={getHeroesList}
          upgradeCards={upgradeCards}
          universalShards={universalShards}
          treesData={treesData}
          // nodes={nodes}
          // edges={edges}
          rating={rating}
          rarity={rarity}
          // onNodeClick={handleNodeClick}
          // selectedSkill={selectedSkill}
          heroId={id}
          offer={offerKeys}
          onClose={() => setIsShowHeroTier(false)}
        />
      )}

      {!isShowHeroTier && (
        <div
          className={`w-full absolute h-[50%] left-0 bottom-0 flex flex-col  overflow-hidden z-1 bg-gradient-to-t from-[#201B18] via-[#201B18]/100 via-70% to-transparent to-90%`}
        >
          <AnimatePresence>
            {isVisible && (
              <motion.div
                className="p-2 relative top-[15%] overflow-hidden flex-shrink-0"
                style={{
                  background: "radial-gradient(circle, rgba(250, 186, 60, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                }}
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.5 }}
              >
                {/* Верхня Рамка */}
                <div
                  className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                  style={{
                    background: "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                  }}
                ></div>

                {/* Нижня Рамка */}
                <div
                  className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                  style={{
                    background: "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                  }}
                ></div>

                {/* Вміст Елемента */}
                {nextLevel && (
                  <div className="flex justify-center items-center gap-2">
                    <div className="text-center text-[#ffefcb] text-base font-black leading-none">
                      {t(`bonusTypes.${nextLevel.bonusType}`)}: {nextLevel.bonusAmount}
                    </div>
                    <img
                      className="w-[18px] h-[18px]"
                      src={require("../../../../assets/images/heroes/upgradeIcons/up.png")}
                      alt="Upgrade Icon"
                    />
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>

          <div className="absolute w-full top-1/4 flex flex-col h-full ">
            <div
              className={`flex gap-4 justify-center items-end pb-3 ${
                farmTutorialSave?.stage === TutorialFarmStage.clickOnHero && "brightness-50"
              }
                ${farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade ? "z-[12] " : ""}`}
            >
              <div
                className="relative"
                onClick={() => {
                  if (farmTutorialSave?.stage !== TutorialFarmStage.clickOnHero) {
                    setIsShowHeroInfo(true);
                  }
                }}
              >
                <img
                  src={require("../../../../assets/images/heroes/icons/buttonIcon.png")}
                  alt="Button Icon"
                  className="w-9 h-9"
                />
                <div className="absolute bottom-2 inset-x-0 flex justify-center items-center">
                  <img
                    className="w-[10px] h-[22px]"
                    src={require("../../../../assets/images/heroes/icons/info.png")}
                    alt="Info Icon"
                  />
                </div>
              </div>

              <HeroRating
                claimedLevels={rating.claimedLevels}
                totalLevels={rating.totalLevels}
                starsWidth={"w-[32px]"}
              />

              <div
                className={`relative ${
                  farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade ? "animate-highlight" : ""
                }`}
                onClick={() => {
                  if (farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade) {
                    updateSave({
                      stage: TutorialFarmStage.chooseTierToUpgrade,
                    });
                  }
                  setIsShowHeroTier(true);
                }}
              >
                <img
                  src={require("../../../../assets/images/heroes/icons/buttonIcon.png")}
                  alt="Button Icon"
                  className="w-9 h-9"
                />
                <div className="absolute bottom-2 inset-x-0 flex justify-center items-center">
                  <img
                    className="w-[20px] h-[20px]"
                    src={require("../../../../assets/images/heroes/icons/up.png")}
                    alt="Info Icon"
                  />
                </div>
                {hasTierUp && (
                  <img
                    className="absolute top-0 right-0 z-20 w-3 h-3"
                    src={require("../../../../assets/images/heroes/cards/notification.png")}
                    alt="Notification"
                  />
                )}
              </div>

              {/* Finger pointer for clickOnRankUpgrade stage */}
              {farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade && (
                <>
                  <div className={`absolute w-[3em] h-[3em] right-[20px] translate-y-[20%] pointer-events-none `}>
                    <PointerHand />
                  </div>
                  <div className={`absolute z-[9999] translate-y-[-100%]`}>
                    <TutorialTooltip
                      value="You can upgrade the hero's rank by clicking this button."
                      type="bottom_right"
                      fontSize="14px"
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`relative bg-[#332b26] rounded-tl-[1px] rounded-tr-[1px] rounded-br shadow-inner-sm-black border 
              border-[#18191a] mx-8 flex items-center mb-2 ${
                farmTutorialSave?.stage === TutorialFarmStage.clickOnHero && "brightness-50"
              }`}
            >
              <img
                src={require("../../../../assets/images/cards-modal-border.png")}
                className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
                alt=""
              />
              <img
                src={require("../../../../assets/images/cards-modal-border.png")}
                className="absolute w-[17.5px] h-[21px] -top-[6px] -right-[5px] transform scale-x-[-1]"
                alt=""
              />
              <div className="flex-1 flex flex-col items-center py-1 gap-1">
                <div className="text-[#dfd9c4] text-sm font-light leading-[14px]">{t("heroes.stats.mana")}</div>
                <div className="flex gap-1 justify-center items-center">
                  <img
                    src={require("../../../../assets/images/heroes/icons/mana.png")}
                    alt="Mana Icon"
                    className="w-5 h-5"
                  />
                  <div className="text-white text-xl font-normal leading-tight">{mana}</div>
                </div>
              </div>

              <div className="w-[1px] self-stretch bg-gradient-to-b from-transparent via-[#bea681] to-transparent"></div>

              <div className="relative flex-1 flex flex-col items-center py-1 gap-1">
                <div className="text-center text-[#dfd9c4] text-sm font-light leading-[14px] z-1">
                  {t("heroes.stats.health")}
                </div>
                <div className="flex gap-1 justify-center items-center z-1">
                  <img
                    src={require("../../../../assets/images/heroes/icons/health.png")}
                    alt="Health Icon"
                    className="w-5 h-5"
                  />
                  <div className="text-white text-[25.01px] font-normal leading-[25.01px]">{health}</div>
                </div>
                <div
                  className="absolute -bottom-[0.6rem] left-0 w-full h-3 bg-[#332b26] border-b border-[#18191a]"
                  style={{ clipPath: "polygon(50% 80%, 0 0, 100% 0)" }}
                ></div>
              </div>
              <div className="w-[1px] self-stretch bg-gradient-to-b from-transparent via-[#bea681] to-transparent"></div>

              <div className="flex-1 flex flex-col items-center py-1 gap-1">
                <div className="text-center text-[#dfd9c4] text-sm font-light leading-[14px]">
                  {t("heroes.stats.cardsHeld")}
                </div>
                <div className="flex gap-1 justify-center items-center">
                  <img
                    src={require("../../../../assets/images/heroes/icons/cards.png")}
                    alt="Cards Icon"
                    className="w-5 h-5"
                  />
                  <div className="text-white text-xl font-normal leading-tight">{cardsAmount}</div>
                </div>
              </div>
            </div>

            <div className={`flex flex-col gap-2 justify-center items-center `}>
              <div className={`flex gap-2 justify-center items-center `}>
                <div className="w-6 h-6">
                  <XPIco />
                </div>
                <div className="flex gap-1">
                  <div
                    className={`text-right ${
                      price > currentValue ? "text-[#dd5444]" : "text-[#3adc96]"
                    }   text-sm font-normal uppercase leading-[14px]`}
                  >
                    {currentValue}
                  </div>
                  <div className="text-right text-white text-sm font-normal uppercase leading-[14px]">/</div>
                  <div className="text-right text-white text-sm font-normal uppercase leading-[14px]">{price}</div>
                </div>
              </div>

              {level >= maxLevel ? (
                <div className="pt-10 w-full h-14 px-10 flex justify-center items-center text-center text-3xl text-[#f6a000]">
                  You have reached the highest level
                </div>
              ) : (
                <div
                  className={`fixed bottom-[36px] w-full h-14 px-20 flex justify-center items-center ${
                    farmTutorialSave?.stage === TutorialFarmStage.clickOnHero ? "z-[200] animate-highlight" : ""
                  }`}
                >
                  <PopupButton
                    type={"green"}
                    width="240px"
                    onClick={level > maxLevel || price > currentValue ? handleNoExpPromoteButPack : handleLevelUp}
                  >
                    <div className={`text-center text-[#ffefcb] text-xl font-normal leading-tight`}>
                      {t("heroes.upgrade")}
                    </div>
                    {hasLevelUp && (
                      <img
                        className="absolute top-0 right-0 z-50 w-4 h-4"
                        src={require("../../../../assets/images/heroes/cards/notification.png")}
                        alt="Notification"
                      />
                    )}
                  </PopupButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showOffer && (
        <div className="fixed inset-0 min-w-full min-h-full z-20">
          {/* Чорний фоновий шар */}

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.9)] via-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0.9)] bg-opacity-50 z-20 pointer-events-none"></div>

          {/* Компонент Offer, який має бути поверх чорного фону */}
          <div className="absolute inset-0 z-30 flex items-center justify-center mb-[-40px] top-[70px]">
            <Offer
              onClose={() => setShowOffer(false)}
              onClick={() => confirmPurchase({ offerId: offer.id })}
              offerType={offer.offerType}
              id={offer.id}
              rankPoints={defineCountString(Math.floor(Number(offer.price) / 10))}
              resourceList={offer.resourceList}
              layout={offer.layout}
              type={offer.type}
              label={offer.label}
              kitsu={offer.kitsu}
              price={offer.price}
              deadline={offer.deadline}
              currentAmount={currentValue}
              needAmount={price}
            />
          </div>

          <div className={`absolute inset-0  ${isSuccessPurchase ? "z-40" : ""} flex items-center justify-center`}>
            <SuccessOverlay isSuccess={isSuccessPurchase} />
          </div>
        </div>
      )}
    </>
  );
};
