import {Layout,Fit,Alignment, useRive,} from "@rive-app/react-canvas";

export const QuestAnimation = ({className,}: { className?: string; }) => {
    const { RiveComponent } = useRive({
        src: "animation/dailyquestclaim.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.Center,
        }),
    });

    return <RiveComponent className={className} />;
};
