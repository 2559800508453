import React, { useEffect, useRef, useState } from 'react';
import { MySlot } from './components'; // Ваш компонент слоту
import { OpenLootBox } from '../Inventory/components';
import { DisplayData, handleLootBoxResult } from '../../utils/lootBoxHandler';
import { Offer, OfferProps } from '../../pages/Shop/components/Offer';
import { PopupButton } from '../../components/PopupButton';
import { AnimatePresence, motion } from 'framer-motion';
import { usePurchase } from '../../hooks/usePurchase';
import { defineCountString } from '../../utils/farmConfigParser';
import { SuccessOverlay } from '../../components/SuccessOverlay';
import { KeysIco } from '../../layout/components/HeaderFarm/components';
import { ItemType, SlotResult } from '../../interfaces/lootBotx';

interface SlotsInterface {
  rewards: DisplayData[];
  offer: OfferProps;
  keys: number;
  needKeys: number;
  spinSlot: () => void;
  slots: SlotResult[];
}

export const SlotMachine: React.FC<SlotsInterface> = ({ rewards, offer, keys, needKeys, spinSlot, slots }) => {
  // Масив зі станом запуску кожного зі слотів
  // [слот1, слот2, слот3] => true = обертається, false = не обертається
  const [slotSpin, setSlotSpin] = useState<[boolean, boolean, boolean]>([false, false, false]);
  const [slotSpinItem, setSlotSpinItem] = useState<SlotResult[] | null>(null);
  const [showOffer, setShowOffer] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const [isAnimating, setIsAnimating] = useState(true);
  // Допоміжна функція, щоб одразу почистити всі setTimeout
  const clearAllTimeouts = () => {
    timeoutsRef.current.forEach((t) => clearTimeout(t));
    timeoutsRef.current = [];
  };

  const handleQuickSpin = () => {
    clearAllTimeouts();
    setSlotSpin([false, false, false]);
    setIsAnimating(false);
    setTimeout(() => {
      setShowRewards(true);
    }, 500);
  };

  const handleStartSpin = () => {
    console.log('here 2');
    clearAllTimeouts();
    setShowRewards(false);
    setTimeout(() => {
      setIsAnimating(true);
    }, 0);

    // Увімкнули всі слоти
    setSlotSpin([true, true, true]);

    // Послідовне зупинення – тільки приклад:
    const t1 = setTimeout(() => {
      setSlotSpin([false, true, true]);
    }, 600);

    const t2 = setTimeout(() => {
      setSlotSpin([false, false, true]);
    }, 1200);

    const t3 = setTimeout(() => {
      setSlotSpin([false, false, false]);

      setTimeout(() => {
        setTimeout(() => {
          setIsAnimating(false);
        }, 0);
        setShowRewards(true);
      }, 500);
    }, 1800);

    timeoutsRef.current.push(t1, t2, t3);
  };

  const onSpinClick = () => {
    spinSlot();
  };
  useEffect(() => {
    if (rewards && slots) {
      setSlotSpinItem(slots);
      handleStartSpin();
    }
  }, [rewards, slots]);

  return (
    <div
      className={`relative bg-[url('./assets/images/summon-bg.webp')] bg-contain bg-center bg-no-repeat flex flex-col overflow-hidden w-full h-full left-0 top-0 items-center justify-center`}
    >
      <div className="absolute inset-0 pointer-events-none bg-black opacity-75"></div>
      <div className="absolute bottom-0 w-full h-[45vh] bg-gradient-to-b from-transparent via-[rgba(27,27,27,0.97)] to-[#201b18]"></div>

      <div className="flex flex-col gap-16">
        {slotSpinItem ? (
          <div className="flex gap-4 justify-center items-center">
            {/* Передаємо стан кожного слоту окремо */}
            <MySlot startSpin={slotSpin[0]} slotSpinItem={slotSpinItem[0]} />
            <MySlot startSpin={slotSpin[1]} slotSpinItem={slotSpinItem[1]} />
            <MySlot startSpin={slotSpin[2]} slotSpinItem={slotSpinItem[2]} />
          </div>
        ) : null}
        {showRewards ? (
          <OpenLootBox
            rewards={rewards}
            openBoxName={'Reward gained!'}
            onClose={() => {
              setShowRewards(false);
            }}
          />
        ) : null}
      </div>
      <AnimatePresence>
        {!isAnimating ? (
          <>
            {
              <motion.div
                className="flex gap-4 absolute bottom-[44px]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                {keys >= 1 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <div className="flex items-center justify-center">
                      <div className="ml-1 font-medium text-base text-white">1</div>
                      <div className="w-5 h-5">
                        <KeysIco />
                      </div>
                    </div>
                    <PopupButton
                      type={'gold'}
                      size="big"
                      onClick={onSpinClick}
                      disabled={isAnimating}
                      className="transition-colors duration-300 ease-in-out"
                    >
                      <div className="flex justify-cneter items-center px-4">Spin</div>
                    </PopupButton>
                  </motion.div>
                )}
              </motion.div>
            }
          </>
        ) : (
          <>
            {isAnimating && (
              <motion.div
                className="absolute bottom-[44px]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <PopupButton
                  size="big"
                  type={'green'}
                  onClick={handleQuickSpin}
                  className="transition-colors duration-300 ease-in-out"
                >
                  <div className="flex justify-cneter items-center px-4">Quick Flip</div>
                </PopupButton>
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
      {keys < 1 && !isAnimating && (
        <motion.div
          className="absolute bottom-[20px]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <PopupButton
            type={'green'}
            onClick={() => setShowOffer(true)}
            className="transition-colors duration-300 ease-in-out"
          >
            Summon x1
          </PopupButton>
          {showOffer && (
            <div className="fixed inset-0 min-w-full min-h-full z-20">
              {/* Чорний фоновий шар */}

              <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

              <div className="absolute inset-0 z-30 flex items-center justify-center top-[70px]">
                <Offer
                  onClose={() => setShowOffer(false)}
                  onClick={() => confirmPurchase({ offerId: offer.id })}
                  offerType={offer.offerType}
                  id={offer.id}
                  resourceList={offer.resourceList}
                  rankPoints={defineCountString(Math.floor(Number(offer.price) / 10))}
                  layout={offer.layout}
                  type={offer.type}
                  label={offer.label}
                  kitsu={offer.kitsu}
                  price={offer.price}
                  deadline={offer.deadline}
                  currentAmount={keys}
                  needAmount={needKeys}
                />
              </div>
            </div>
          )}
        </motion.div>
      )}
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};
