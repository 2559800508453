import { useTranslation } from "react-i18next";
import { OfferResource } from "../../../../pages/Shop/components/OfferResources";
import { PopupButton } from "../../../../components/PopupButton";
import React, { useState } from "react";
import {
  MissionCondition,
  MissionProgress,
  MissionTask,
  MissonReward,
  TaskStatus
} from "../../../../app/features/questsSlice";
import { getMissions } from "../../../../endpoints/userQuests";
import { useTelegram } from "../../../../hooks/useTelegram";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {QuestAnimation} from "../../../../components";

// export enum ConditionType {
//     clearDungeon,
//     upgradeBuilding,
//     lvlUpHero,
//     tierUpHero,
//     useKeys,
//   }

export interface CombinedMission {
  config: {
    questId: number;
    rewards: MissonReward[];
    baseQuestId?: number;
    conditions: MissionCondition[];
  };
  id: number;
  status: {
    isDone: boolean;
    progress: MissionProgress[];
  };
  mainMissionOnClick?: () => void;
  secondaryMissionOnClick?: () => void;
}

export const MissionBlock = ({ mission, heroLvl, heroTiers }: { mission: CombinedMission, heroLvl: number, heroTiers: number }) => {
  const { t } = useTranslation();
  const { config, id, status, mainMissionOnClick, secondaryMissionOnClick } = mission;
  const [isClaimed, setIsClaimed] = useState(false);
  const { userId } = useTelegram();
  const kitsuShrineLvl = useSelector((state: RootState) => state.islands.islands[0].buildings[0].lvl);
  const [showSuccess, setShowSuccess] = useState(false);
  const message = config?.conditions.map((condition: MissionCondition) => {
    const { value, type, buildingId } = condition;
    console.log('conditions', kitsuShrineLvl)
    const action = t(`missions.condition.actionType.${type.toString()}`);
    const dungeonByBuildingId: Record<number, number> = {
      0: 0,
      1: 0,
      2: 1,
      3: 2,
      4: 4,
      5: 7,
      6: 10,
      7: 13
    }
    let finalValue
    switch(type){
      case 0:{
        if(config.baseQuestId){
          finalValue = t(`buildings.titles.${buildingId}`)+`, stage ${dungeonByBuildingId[buildingId]-value}` 
        }else{
          finalValue = t(`buildings.titles.${buildingId}`)
        }
        break;
      }
      case 1:{
        finalValue = t(`buildings.titles.${buildingId}`)+` lvl ${value}` 
        break;
      }
      case 2:{
        finalValue = `${Math.min(value, heroLvl)}/${value}`
        break;
      }
      case 3:{
        finalValue = `${Math.min(heroTiers,value)}/${value}`
        break;
      }
      case 4:{
        finalValue = value
        break;
      }
    }

    const fullMessage = `${action}: ${finalValue}`;
    return fullMessage;
  });

  const revalidateMission = async () => {
    const result = await getMissions({ clientId: userId });
  };

  //console.log('status', mission)

  return (
    <div
      className={` ${isClaimed ? "hidden" : ""} w-full relative min-h-[80px] bg-[#463d36] rounded-md`}
      style={{ boxShadow: "inset 0px -1px #2a2b2d, 0px 1px #1d0306" }}
    >
      <div
        className={`w-full h-full rounded-md px-[8px] pt-[5px] pb-[7px]`}
        style={{ boxShadow: "inset 1px 1px rgba(255,255,255,0.1), inset -1px -1px rgba(255,255,255,0.1) " }}
      >
        <div className={`flex w-full text-[white] text-stroke-small text-[17px]`}>
          <div className={`flex flex-col leading-tight`}>
            {message}
            <div className={`w-fit h-[46px] flex bg-[#39312c] justify-start items-center p-1 rounded-md gap-1`}>
              {config?.rewards.map((reward: MissonReward) => (
                <OfferResource
                  key={reward.rewardId}
                  resourceId={reward.rewardId}
                  resourceType={reward.rewardType}
                  amount={(reward.amount * (kitsuShrineLvl ?? 1)).toString()}
                  isEnabled={false}
                  layout="wide"
                />
              ))}
            </div>
          </div>
          <div className={`flex grow justify-end items-end `}>
            {showSuccess ? (
                <>
                  <div className="w-[300px] h-[300px] right-[-105px] bottom-[-129px] absolute z-50">
                    <QuestAnimation />
                  </div>
                </>
            ) : null}
            <PopupButton
              width={`72px`}
              onClick={() => {
                if (status.isDone) {
                  setShowSuccess(true);
                  setTimeout(() => {
                    setShowSuccess(false);
                  }, 1000);
                }
                if (mainMissionOnClick) {
                  mainMissionOnClick();

                } else if (secondaryMissionOnClick) {
                  secondaryMissionOnClick();
                  if (status?.isDone) {
                    setShowSuccess(true);
                    setTimeout(() => {
                      setShowSuccess(false);
                    }, 2000);
                    setTimeout(()=>{
                      setIsClaimed(true);
                      revalidateMission();
                    },2200) //закончиться аниманияs
                  }
                }
              }}
              type={`${status?.isDone ? "green" : "blue"}`}
            >
              {status?.isDone ? "Claim" : "Go"}
            </PopupButton>
          </div>
        </div>
      </div>
    </div>
  );
};
