import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { SlotRoll } from '../../../../components';
import { resourcesMap } from '../../../../utils/mapping';
import { Resources } from '../../../../enums/resources';
import { SlotResult } from '../../../../interfaces/lootBotx';
import { handleLootBoxResult } from '../../../../utils/lootBoxHandler';
import { handleRewardResult } from '../../../../utils/rewardParse';

interface MySlotProps {
  startSpin: boolean;
  slotSpinItem: SlotResult;
}

export const MySlot: React.FC<MySlotProps> = ({ startSpin, slotSpinItem }) => {
  const slotItemData = useMemo(() => {
    if (!slotSpinItem) return;
    const result = handleRewardResult({ rewardId: slotSpinItem.rewardId, rewardType: slotSpinItem.rewardType });

    if (!result) return;
    
    return result;
  }, [slotSpinItem]);
  return (
    <div className="relative">
      <motion.div
        className={`absolute w-20 h-28 bottom-[90%]`}
        style={{
          background: `linear-gradient(to top, rgba(255,197,36,0.7), rgba(255,197,36,0))`,
          filter: 'blur(4px)',
          zIndex: 0,
        }}
        animate={
          startSpin
            ? { opacity: 1 } // повна непрозорість і на місці
            : { opacity: 0.5 }
        }
        transition={{ duration: 0.5 }}
      />

      {/* (A) Нижній градієнт, який змінює прозорість і "виїжджає" знизу */}
      <motion.div
        className={`absolute w-20 h-28 top-[90%]`}
        style={{
          background: `linear-gradient(to bottom, rgba(255,197,36,0.7), rgba(255,197,36,0))`,
          filter: 'blur(4px)',
          zIndex: 0,
        }}
        animate={startSpin ? { opacity: 1 } : { opacity: 0.5 }}
        transition={{ duration: 0.5 }}
      />

      {/* (B) Обгортка, яка при startSpin може, наприклад, трохи "підстрибувати" */}
      <motion.div
        className={`${startSpin ? 'drop-shadow-gold' : slotItemData ? slotItemData.dropShadow : ''}`}
        initial={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* (B) Сама «фішка» (слот), яка плавно з’являється чи сповзає зверху/знизу */}
        <motion.div
          className="
            z-10 w-[80px] h-[80px] bg-[#f2e09c]
            filter drop-shadow-[0_0_6px_rgba(255,255,0,1)]
            flex justify-center items-center
          "
          style={{
            clipPath: 'polygon(5% 0%, 95% 0%, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0% 95%, 0% 5%)',
          }}
          transition={{ duration: 0.5 }}
        >
          <div
            className="flex justify-center items-center w-[75px] h-[75px] bg-[#c99647]"
            style={{
              clipPath: 'polygon(5% 0%, 95% 0%, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0% 95%, 0% 5%)',
            }}
          >
            <div
              className="flex justify-center items-center w-[70px] h-[70px] bg-[#48342c]"
              style={{
                clipPath: 'polygon(5% 0%, 95% 0%, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0% 95%, 0% 5%)',
              }}
            >
              {startSpin ? (
                /* Якщо спін активний — показуємо анімацію Rive */
                <SlotRoll />
              ) : (
                /* Якщо ні — статична картинка (resource) */
                <motion.div
                  className={`
                  flex justify-center items-center
                  w-[65px] h-[65px]
                  ${slotItemData ? slotItemData.backgroundColor : ''}
                `}
                  style={{
                    clipPath: 'polygon(5% 0%, 95% 0%, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0% 95%, 0% 5%)',
                  }}
                >
                  <div
                    className="
                    flex justify-center items-center
                    w-[62px] h-[62px]
                    bg-[#48342c]
                  "
                    style={{
                      clipPath: 'polygon(5% 0%, 95% 0%, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0% 95%, 0% 5%)',
                    }}
                  >
                    <motion.div // Спочатку - вище й невидимий
                      initial={{ y: -30, opacity: 0 }}
                      // При зміні (або коли компонент з'являється) - опускається та з’являється
                      animate={{ y: 0, opacity: 1 }}
                      // Тривалість та крива прискорення
                      transition={{ duration: 0.5, ease: 'easeOut' }}
                      className="w-[55px] h-[55px]"
                    >
                      <img
                        src={slotItemData ? slotItemData?.image : require('../../../../assets/images/noResouce.webp')}
                        className="w-full h-full"
                        alt="resource"
                      />
                    </motion.div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};
