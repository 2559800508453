import { useSelector } from 'react-redux';
import { handleRewardResult } from '../../../../containers/QuestsList/components/SocialBlock/rewardParse';
import { ItemType } from '../../../../interfaces/lootBotx';
import { RootState } from '../../../../app/store';

export interface OfferResourceProps {
  resourceType: number;
  resourceId: number;
  isEnabled: boolean;
  amount: string;
  layout: 'slim' | 'wide' | 'vertical' | 'full' | 'rank';
}

export const OfferResource = (props: OfferResourceProps) => {
  const resourceType: number = props?.resourceType;
  const resourceId: number = props?.resourceId;
  const resourceData = handleRewardResult({ rewardType: resourceType, rewardId: resourceId });
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    boosts: { variables: boostsConfig },
  } = appConfig;
  const mappedBoosts = boostsConfig.map((boost: any) => boost.nominal.value);

  const layouts = {
    rank: {
      1: 'border-[3px] border-[#ffff2f] min-w-[3em] w-[3em] drop-shadow-[0px_0px_3px_#dc8319]',
      2: 'min-w-[32px] w-[32px]',
      3: 'text-[0.8em]',
      4: 'text-[0.8em]',
    },
    full: {
      1: 'flex justify-center items-center w-[80px] aspect-square border-[3px] border-[#ffff2f] my-[7px] ml-[7px] drop-shadow-[0px_0px_3px_#dc8319]',
      2: 'flex justify-center items-center  w-[55px] aspect-square',
      3: 'text-[20px] bottom-[2px]',
      4: 'text-[13px] bottom-[2px]',
    },
    slim: {
      1: 'border-[3px] border-[#ffff2f] min-w-[3em] w-[3em] drop-shadow-[0px_0px_3px_#dc8319]',
      2: 'min-w-[2.3em] w-[2.3em]',
      3: 'text-[0.8em]',
      4: 'text-[0.6em]',
    },
    wide: {
      1: 'border-[3px] border-[#ffff2f] min-w-[3em] w-[3em] drop-shadow-[0px_0px_3px_#dc8319]',
      2: 'min-w-[2.3em] w-[2.3em] min-h-[2.3em] h-[2.3em]',
      3: 'text-[0.8em] bottom-[2px]',
      4: 'text-[0.6em] bottom-[2px]',
    },
    vertical: {
      1: 'border-[3px] border-[#ffff2f] min-w-[3em] w-[3em] drop-shadow-[0px_0px_3px_#dc8319]',
      2: 'w-[30px] text-lg max-[375px]:w-[27px]',
      3: 'text-[0.8em]',
      4: 'text-[0.6em] bottom-[-7px]',
    },
  };

  const rarities: Record<number, Record<number, string>> = {
    0: {
      0: 'from-[#4045c5] to-[#595ffe]',
      1: 'from-[#4045c5] to-[#595ffe]',
      2: 'from-[#9546df] to-[#d659d8]',
      3: 'from-[#9546df] to-[#d659d8]',
      4: 'from-[#ff8514] to-[#fffb2d]',
    },
    1: {
      0: 'from-[#4045c5] to-[#595ffe]',
      1: 'from-[#9546df] to-[#d659d8]',
      2: 'from-[#ff8514] to-[#fffb2d]',
      3: 'from-[#4045c5] to-[#595ffe]',
    },
    2: {
      0: 'from-[#9546df] to-[#d659d8]',
      1: 'from-[#4045c5] to-[#595ffe]',
      2: 'from-[#4045c5] to-[#595ffe]',
      3: 'from-[#4045c5] to-[#595ffe]',
      4: 'from-[#4045c5] to-[#595ffe]',
    },
    3: {
      0: 'from-[#4045c5] to-[#595ffe]',
      1: 'from-[#9546df] to-[#d659d8]',
      2: 'from-[#ff8514] to-[#fffb2d]',
      3: 'from-[#4045c5] to-[#595ffe]',
    },
    4: {
      0: 'from-[#ff8514] to-[#fffb2d]',
    },
  };
  //rarities[props.resourceType][props.resourceId]

  return (
    <div
      className={`relative overflow-hidden bg-gradient-to-t ${rarities[resourceType][resourceId]} aspect-square border-solid 
            ${
              props?.isEnabled ? layouts[props?.layout][1] : layouts[props?.layout][2]
            } rounded-md border-[1px] border-[#18191b] `}
    >
      {resourceType === ItemType.timeBoosts && (
        <div className="absolute top-0 left-0 right-0 h-[30%] flex items-center justify-center">
          <span
            className={`text-[0.6em] mt-[5%] z-10 text-white text-center text-stroke-small leading-none
                          ${props?.isEnabled ? layouts[props?.layout][3] : layouts[props?.layout][4]}`}
          >
            {mappedBoosts[resourceId] / 60000}m
          </span>
          <div className="absolute w-full h-full border-b border-black bg-[#333693]"></div>
        </div>
      )}
      <img className={`${resourceType === ItemType.timeBoosts ? 'mt-[15%]' : ''}`} src={resourceData?.image} alt="" />
      <span
        className={`block absolute text-white right-[2px] text-stroke-small 
                ${props?.isEnabled ? layouts[props?.layout][3] : layouts[props?.layout][4]}`}
      >
        {props.amount}
      </span>
    </div>
  );
};
