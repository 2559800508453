import { useDispatch, useSelector } from 'react-redux';
import { PopupButton } from '../../../../components/PopupButton';
import { OfferType } from '../../../../mock/offers';
import { OfferResource, OfferResourceProps } from '../OfferResources';
import Timer from '../Timer';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../app/store';
import { setSelectedRoom } from '../../../../app/features/selectedRoom';
import { useUtils } from '../../../../utils/navigateTo';
import { useSessionStorage } from '@uidotdev/usehooks';
import { RoomStatus } from '../../../../enums/buildingStatus';
import { Resources } from '../../../../enums/resources';
import { useMemo, useState } from 'react';
import { resourcesMap } from '../../../../utils/mapping';
import { QuantitySelector } from '../../../../containers/Inventory/components';
import { PopupWindow } from '../../../../components/PopupWindow';
import { swap } from '../../../../endpoints/farmMock';
import { useTelegram } from '../../../../hooks/useTelegram';
import { ResourcePurchase } from '../../../../components';
import { SuccessOverlay } from '../../../../components/SuccessOverlay';
import { Swap } from '../../../../interfaces/swap';
import { defineCountString } from '../../../../utils/farmConfigParser';
import { ItemType } from '../../../../interfaces/lootBotx';

export interface OfferProps {
    id: number;
    resourceList?: OfferResourceProps[];
    offerType?: OfferType;
    layout?: 'slim' | 'wide' | 'vertical' | 'full' | 'rank';
    type?: 'blue' | 'gold' | 'none';
    label?: 'corner' | 'top' | 'vertical_corner' | 'full_top';
    kitsu?: string;
    price?: string;
    deadline?: string;
    isClaimed?: boolean;
    rankPoints?: string;
    currentAmount?: number;
    needAmount?: number;
    onClick?: () => void;
    onClose?: () => void;
    onEscape?: () => void;
}

const mockResourceList: OfferResourceProps[] = [
    {
        resourceType: 2,
        resourceId: 2,
        isEnabled: false,
        amount: '6',
        layout: 'vertical',
    },
    {
        resourceType: 2,
        resourceId: 0,
        isEnabled: false,
        amount: '250K',
        layout: 'vertical',
    },
    {
        resourceType: 1,
        resourceId: 0,
        isEnabled: false,
        amount: '5',
        layout: 'vertical',
    },
    {
        resourceType: 0,
        resourceId: 0,
        isEnabled: false,
        amount: '25',
        layout: 'vertical',
    },
];

export const Offer = (props: OfferProps) => {
    const { t } = useTranslation();

    const {
        id = '0',
        offerType = OfferType.kitsune,
        resourceList = mockResourceList,
        layout = 'slim',
        type = 'gold',
        label = 'corner',
        kitsu = '2500',
        price = 0,
        deadline = 'November 21, 2024',
        isClaimed = false,
        rankPoints,
        needAmount,
        currentAmount,
        onEscape,
        onClick,
        onClose,
    } = props;
    const [startSelectHero, setStartSelectHero] = useSessionStorage('startSelectHero', false);
    const [openKitsuToKeySwap, setOpenKitsuToKeySwap] = useSessionStorage('openKitsuToKeySwap', false);
    const resources = useSelector((state: RootState) => state.resources.resources);
    const { userId } = useTelegram();

    const currentKitsu = resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;
    const currentKeys = resources.find((v) => v.resourceType === Resources.keys)?.value || 0;

    const island = useSelector((state: RootState) => state.selectedIsland);
    const room = useSelector((state: RootState) => state.selectedRoom);
    const buildings = useSelector((state: RootState) => state.config);

    const battleBuilding = island?.selectedIsland?.buildings?.filter((b) => {
        return b.status === 1;
    });

    const dispatch = useDispatch();
    const { navigateTo } = useUtils();

    const colors = {
        gold: {
            1: 'from-[#E4E180] to-[#FD8D4D]',
            2: '',
            3: '',
        },
        blue: {
            1: 'from-[#3d54c8] to-[#3d54c8]',
            2: 'hidden',
            3: 'text-[14px]',
        },
        none: {
            1: '',
            2: '',
            3: '',
        },
    };
    const layouts = {
        rank: {
            1: 'w-full h-[94px] rounded-[4px] bg-gradient-to-r border border-[#372C25] shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset] ',
            2: 'hidden z-10',
            3: 'hidden',
            4: "h-full grid grid-cols-2 grid-rows-2 grid-flow-col auto-rows-auto justify-around items-center pb-1 bg-[url('./assets/images/shop/offer/offer-rank-bg.png')] bg-cover ",
            5: 'hidden',
            6: 'w-min-[60%] ml-1',
            7: 'hidden',
            8: ' absolute text-[21px] top-[-20px]',
            9: 'hidden',
            10: 'flex w-full min-w-full gap-1',
            11: ' row-span-2 mt-3 mr-2 ml-3 font-[800] justify-self-end self-end ',
            12: '100px',
            13: 'text-[12px]',
            14: 'relative flex gap-1 text-[24px] bg-gradient-to-r from-[#d95bd8] via-[#d95bd8] to-transparent ml-1',
            15: 'top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] ',
        },
        full: {
            1: `w-full h-auto rounded-[4px] ${
                offerType === OfferType.kitsune
                    ? ''
                    : ' border border-[#372C25] shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset]'
            }  `,
            2: `hidden`,
            3: `hidden`,
            4: `flex flex-col justify-around items-center h-[165px] ${
                offerType === OfferType.kitsune ? 'bg-transparent' : 'bg-gradient-to-r'
            }  mx-[16px] rounded-md`,
            5: `absolute ${offerType === OfferType.kitsune ? 'w-[80%]' : 'w-[50%] -translate-y-[85%]'}   h-auto  z-[-1]`,
            6: `w-full px-[40px]`,
            7: `absolute w-full left-0 -translate-y-[50%] ${offerType === OfferType.kitsune ? 'hidden' : ''} `,
            8: `relative top-[-30px] text-[24px] w-full text-center ${offerType === OfferType.kitsune ? 'hidden' : ''}`,
            9: `hidden`,
            10: `${
                offerType === OfferType.kitsune ? 'hidden' : 'flex'
            }  justify-center items-center w-full min-w-full h-[92px] gap-[7px]`,
            11: `${offerType === OfferType.kitsune ? 'translate-y-[100%]' : 'translate-y-[30%]'} font-[800]`,
            12: `134px`,
            13: `text-[12px]`,
            14: `${
                offerType === OfferType.kitsune
                    ? 'flex text-[30px] absolute top-[-15%] px-[20px] text-center'
                    : 'hidden'
            }`,
            15: ``,
        },
        slim: {
            1: 'w-full h-[64px] rounded-[4px] bg-gradient-to-r border border-[#372C25] shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset]',
            2: 'z-10',
            3: '',
            4: 'flex justify-around items-center',
            5: 'w-[4.3em] min-w-[4.3em] h-auto drop-shadow-[-3px_3px_1px_rgba(74,74,52,0.8)] z-[2]',
            6: 'w-[36%]',
            7: 'hidden',
            8: ' relative text-[1.3em] text-nowrap overflow-visible',
            9: 'hidden',
            10: 'flex w-full min-w-full gap-1',
            11: 'mt-3 mr-2 ml-3 font-[800]',
            12: '80px',
            13: '',
            14: 'hidden',
            15: 'scale-[0.7] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]',
        },
        wide: {
            1: 'w-full h-[138px] rounded-[4px] bg-gradient-to-r border border-[#372C25] shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset]',
            2: 'z-[1]',
            3: '',
            4: "flex justify-between items-center flex-row-reverse p-1 bg-[url('./assets/images/shop/offer/offer-wide-bg.png')] bg-cover bg-no-repeat ",
            5: 'absolute w-[140px] h-auto mt-[-6px] drop-shadow-[-3px_3px_1px_rgba(74,74,52,0.8)] z-[2]',
            6: 'w-full pl-1',
            7: 'hidden',
            8: ' relative text-[2em]',
            9: '',
            10: 'flex relative w-[50%] min-w-[50%] gap-1 scale-[1.1] my-1 ml-2 z-[2]',
            11: 'absolute right-[10px] bottom-[10px] mt-3 mr-2 ml-3 font-[800]',
            12: '80px',
            13: 'hidden',
            14: 'hidden',
            15: 'scale-[1.2] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]',
        },
        vertical: {
            1: "w-[112px] bg-[url('./assets/images/shop/offer/offer-vertical-bg.png')] bg-cover bg-no-repeat border border-[#372C25] shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset]",
            2: 'hidden',
            3: 'hidden',
            4: 'flex flex-col justify-around items-center h-full ',
            5: 'w-[35%] min-w-[35%] pt-2  z-[2]',
            6: 'flex flex-col items-center w-[95%]',
            7: 'hidden',
            8: 'hidden',
            9: 'hidden',
            10: 'grid grid-cols-2 w-[69px] h-[98px] aspect-square justify-items-center gap-1 scale-[0.9]',
            11: 'mb-[10px] font-[800]',
            12: '60px',
            13: 'hidden',
            14: 'flex',
            15: ' -rotate-[70deg] top-[50%] translate-y-[-50%] left-0 translate-x-[-25%]  scale-[0.7] ',
        },
    };

    const labels = {
        corner: {
            1: 'absolute z-30 right-[-10px] top-[-16px] w-[3em]',
            2: 'absolute z-30 right-[-24px] top-[2px] w-[65px] h-[18px] flex justify-center items-center text-white text-[12px] text-stroke-small rotate-45 ',
        },
        top: {
            1: 'absolute z-10 right-[20px] top-[-7px] w-[9em]',
            2: 'absolute z-10 right-[55px] top-[-4px] w-[65px] h-[18px] flex justify-center items-center text-white text-[16px] text-stroke-small',
        },
        vertical_corner: {
            1: 'absolute z-10 right-[-7px] top-[-12px] w-[3em]',
            2: 'absolute z-10 right-[-20px] top-[5px] w-[65px] h-[18px] flex justify-center items-center text-[10px] text-white text-stroke-small rotate-45',
        },
        full_top: {
            1: `absolute z-10 right-[90px] top-[24px] w-[126px] ${offerType === OfferType.kitsune ? 'hidden' : ''}`,
            2: `absolute z-10 right-[115px] top-[24px] w-[65px] h-[18px] flex justify-center items-center text-white text-[16px] text-stroke-small ${
                offerType === OfferType.kitsune ? 'hidden' : ''
            }`,
        },
    };

    const sales: Record<number, string | undefined> = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '602%',
        7: '960%',
        8: '900%',
        9: '1090%',
        10: '1193%',
        11: '2839%',
        12: '2527%',
        13: '1090%',
        14: '2340%',
        15: '602%',
        16: '2340%',
        17: '900%',
        18: '2340%',
        19: '1090%',
        20: '900%',
        21: '2340%',
        22: '900%',
        23: '2340%',
        24: '900%',
        25: '1090%',
        26: '602%',
        27: '2340%',
        28: '1090%',
        29: '2527%',
        31: '1090%',
        32: '2340%',
        33: '2527%',
    };

    const currentResource: Record<number, string> = {
        0: 'KITSU',
        1: 'XP',
        2: 'Speed Up',
        3: 'Stone',
        4: 'Keys',
        5: 'Rank Points',
        6: 'Rank Points',
    };

    const preloadResource: Record<number, string> = {
        0: require('../../../../assets/images/resource/1.webp'),
        1: require('../../../../assets/images/resource/4.webp'),
        2: require('../../../../assets/images/resource/2.png'),
        3: require('../../../../assets/images/resource/3.png'),
        4: require('../../../../assets/images/keys.png'),
        5: require('../../../../assets/images/shop/offer/notify.png'),
        6: require('../../../../assets/images/shop/offer/notify.png'),
    };
    console.log(
        'STUFF',
        buildings?.buildingConfigs?.filter((b) => b.resourceType === 2)
    );

    const getGatheredResources = (buildingId: number) => {
        const room = island?.selectedIsland?.buildings.filter((b) => b.id === buildingId)[0];
        const building = buildings?.buildingConfigs?.filter((b) => b.id === buildingId)[0];
        const timeDifference = room ? Date.now() - new Date(room.statusUpdateDate).getTime() : 0;

        const buildingLvlConfig = building?.lvlInfo.find((lvl) => lvl.lvl === room?.lvl);

        const maxGatheredValue =
            (buildingLvlConfig?.maxTimeMs! / buildingLvlConfig?.gatherDelayMs!) * buildingLvlConfig?.resourceAmount!;

        const gatheredValue = Math.min(
            (timeDifference / buildingLvlConfig?.gatherDelayMs!) * buildingLvlConfig?.resourceAmount!,
            maxGatheredValue
        );
        return gatheredValue;
    };

    const appConfig = useSelector((state: RootState) => state.appConfig.configs);

    const swapKitsuToKeyRate = useMemo(() => {
        if (!appConfig) return;

        const {
            swapRates: { variables: swapRates },
        } = appConfig;

        console.log('swapRates', swapRates);

        if (swapRates) {
            return swapRates.filter((swap: Swap) => swap.id.value === 3);
        }

        return null;
    }, [appConfig]);

    // const parseResourceAmount = (amount: number) => {
    //   console.log('amount', amount)
    //   const res =  amount! > 1000
    //   ? amount! > 1000000
    //     ? amount > 1000000000
    //       ? amount > 1000000000000
    //         ? (amount! / 1000000000000).toPrecision(2) + "t"
    //         : (amount! / 1000000000).toPrecision(2) + "b"
    //       : (amount! / 1000000).toPrecision(2) + "m"
    //     : (amount! / 1000).toPrecision(2) + "k"
    //   : amount;
    //   console.log('res',res)
    //   return res
    // };
    const [isSuccess, setIsSuccess] = useState(false);
    console.log('rankPoints', rankPoints);
    console.log('price', price);
    console.log('layout', layout[13]);
    const handleSwap = async (amount: number, id: number) => {
        const result = await swap({
            clientId: userId,
            id,
            toAmount: amount,
        });

        if (result) {
            setOpenKitsuToKeySwap(false);
            setIsSuccess(true);

            setTimeout(() => {
                setIsSuccess(false);

                if (onClose) onClose();
            }, 2100);
        }
        return result;
    };

    return (
        <div className={`${layout === 'full' ? 'flex flex-col items-center' : ''}`}>
            <div className={`relative ${offerType === OfferType.kitsune ? 'w-full' : ''}`}>
                <div
                    className={`${layouts[layout][1]} ${colors[type][1]} ${!isClaimed ? '' : 'saturate-0'}  relative  z-[2]
            `}
                >
                    {/* Corners of offer block */}
                    <img
                        src={require('../../../../assets/images/shop/offer/corner.png')}
                        className={` ${colors[type][2]} ${layouts[layout][2]} absolute left-[-4px] top-[-4px] -rotate-90 `}
                        alt=''
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/corner.png')}
                        className={` ${colors[type][2]} ${layouts[layout][2]} absolute right-[-4px] top-[-4px] `}
                        alt=''
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/corner.png')}
                        className={` ${colors[type][2]} ${layouts[layout][2]} absolute left-[-4px] bottom-[-4px] rotate-180 `}
                        alt=''
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/corner.png')}
                        className={` ${colors[type][2]} ${layouts[layout][2]} absolute right-[-4px] bottom-[-4px] rotate-90 `}
                        alt=''
                    />

                    {/* Border of the offer block */}
                    <img
                        src={require('../../../../assets/images/shop/offer/border-horizontal.png')}
                        alt=''
                        className={` ${colors[type][2]} ${layouts[layout][3]} absolute top-[-3px] w-full h-[3px] bg-[rgb(255,230,101)] `}
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/border-vertical.png')}
                        alt=''
                        className={` ${colors[type][2]} ${layouts[layout][3]} absolute left-[-3px] w-[3px] h-full bg-[rgb(255,230,101)] `}
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/border-vertical.png')}
                        alt=''
                        className={` ${colors[type][2]} ${layouts[layout][3]} absolute right-[-3px] w-[3px] h-full bg-[rgb(255,230,101)] `}
                    />
                    <img
                        src={require('../../../../assets/images/shop/offer/border-horizontal.png')}
                        alt=''
                        className={` ${colors[type][2]} ${layouts[layout][3]} absolute bottom-[-3px] w-full h-[3px] bg-[rgb(255,230,101)] `}
                    />

                    {/* Offer label */}
                    {/* 1 */}
                    {type != 'blue' && (
                        <>
                            <img
                                src={require('../../../../assets/images/shop/offer/label.png')}
                                className={`${label != 'top' && label != 'full_top' ? labels[label][1] : 'hidden'}`}
                                alt=''
                            />
                            <div className={`${label != 'top' && label != 'full_top' ? labels[label][2] : 'hidden'}`}>
                                {sales[id] != '' ? sales[id] : '1203%'}
                            </div>
                        </>
                    )}
                    {/* 2 */}
                    {type != 'blue' && (
                        <>
                            <img
                                src={require('../../../../assets/images/shop/offer/label2.png')}
                                className={`${label === 'top' || label === 'full_top' ? labels[label][1] : 'hidden'}`}
                                alt=''
                            />
                            <div className={`${label === 'top' || label === 'full_top' ? labels[label][2] : 'hidden'}`}>
                                {sales[id] != '' ? sales[id] : '1203%'}
                            </div>
                        </>
                    )}

                    {/* Close button for fullsize offer */}
                    {layout === 'full' && (
                        <div
                            className={`absolute right-[16px] top-[-55px] w-[3em] h-[3em] z-[100] p-2`}
                            onClick={onClose}
                        >
                            <img
                                src={require('../../../../assets/images/shop-modal-close.png')}
                                className='w-full h-full'
                                alt=''
                            />
                        </div>
                    )}

                    {/* Start of the content block */}
                    <div className={`${layouts[layout][4]}`}>
                        {/* Main picture of the offer */}
                        <div className={`${layouts[layout][5]}`}>
                            <img
                                src={require(`../../../../assets/images/shop/offer/image${offerType}.png`)}
                                className={`w-full h-auto`}
                                alt=''
                            />
                        </div>

                        {/* FOR VERTICAL AND RANK ONLY */}
                        {/* Kitsu rewards */}
                        <div className={`${layouts[layout][14]}  items-center font-bold text-white text-stroke-small `}>
                            {layout === 'rank' && (
                                <>
                                    <div
                                        className={`absolute top-[2px] w-full left-0 h-[1px] bg-[linear-gradient(to_right,#12110e,#9a8c72,#fff,#9a8c72,#12110e)] `}
                                    ></div>
                                    <div
                                        className={`absolute bottom-[2px] w-full left-0 h-[1px] bg-[linear-gradient(to_right,#12110e,#9a8c72,#fff,#9a8c72,#12110e)]`}
                                    ></div>
                                </>
                            )}
                            <img
                                src={require(`../../../../assets/images/kitsu-icon.webp`)}
                                className={` w-[1em] h-[1em] `}
                                alt=''
                            />
                            <p className='inline-block'>{kitsu}</p>
                        </div>

                        {/* Rewards section for all layouts */}
                        <div className={`text-[#4A4432] text-[12px] leading-[1.2] ${layouts[layout][6]}`}>
                            <div className={`${layouts[layout][7]} `}>
                                <img
                                    src={require('../../../../assets/images/shop/offer/title-label.png')}
                                    className={`w-full h-auto`}
                                    alt=''
                                />
                            </div>
                            <p
                                className={`${layouts[layout][8]} ${colors[type][3]} text-white font-bold text-stroke-small z-10`}
                            >
                                {t(`shop.offers.${offerType}.title`)}
                            </p>
                            <div
                                className={` ${
                                    layout === 'wide' ? '' : 'hidden'
                                } w-[50%] h-[3px] my-[0.75em] bg-gradient-to-r from-black to-transparent  `}
                            ></div>
                            {/* <div
                className={` ${layout === "wide" ? "flex" : "hidden"
                  } items-center gap-1`}
              >
                <img
                  src={require("../../../../assets/images/shop/offer/clock.png")}
                  className={`w-[1.5em] h-[1.5em]`}
                  alt=""
                />

                <Timer deadline={deadline} />
              </div> */}
                            <div className={` ${layouts[layout][9]} w-[60%]`}>
                                {t(`shop.offers.${offerType}.description`)}
                            </div>
                            <div
                                className={` items-end ${layouts[layout][10]} overflow-scroll ${
                                    type != 'blue' ? 'bg-[rgb(0,0,0,0.3)]' : 'bg-transparent'
                                }  p-1 rounded-md `}
                            >
                                {resourceList.map((r, index) => (
                                    <OfferResource
                                        key={index}
                                        resourceType={r.resourceType}
                                        resourceId={r.resourceId}
                                        isEnabled={r.isEnabled}
                                        amount={r.amount}
                                        layout={layout}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={`${layouts[layout][11]} z-10`}>
                            <PopupButton
                                type='gold'
                                width={layouts[layout][12]}
                                onClick={onClick}
                                size={layout === 'full' ? 'big' : layout === 'rank' ? 'medium' : 'small'}
                            >
                                {/* Rank points notif button */}
                                {price ? (
                                    <>
                                        {rankPoints && (
                                            <div
                                                className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                                            >
                                                <div
                                                    className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                                                >
                                                    <img
                                                        src={require('../../../../assets/images/shop/offer/notify.png')}
                                                        className={`w-[2em] aspect-square -translate-y-[5%] `}
                                                        alt=''
                                                    />
                                                    {rankPoints}
                                                </div>
                                            </div>
                                        )}
                                        <div className='flex justify-center items-center w-full gap-1'>
                                            <img
                                                className='w-[16px] h-[16px]'
                                                src={require('../../../../assets/images/shop/offer/offer-star.png')}
                                                alt=''
                                            />
                                            <span
                                                className={`text-white text-stroke-regular ${
                                                    layout === 'full' ? 'text-[20px]' : 'text-[14px]'
                                                } leading-[16px] font-[800]`}
                                            >
                                                {price}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    'Collect'
                                )}
                            </PopupButton>
                        </div>
                    </div>
                </div>

                {/* SOLD OUT LABEL */}
                {isClaimed && (
                    <div className={`absolute z-[100] ${layouts[layout][15]} `}>
                        <div className={`relative w-[235px] h-[90px] `}>
                            <img
                                className={`absolute w-full h-full`}
                                src={require('../../../../assets/images/shop/offer/offer-claimed.png')}
                                alt=''
                            />
                            <div className='absolute w-[1.5em] left-[50%] translate-x-[-50%] bottom-0 rotate-[-8deg]'>
                                <Timer deadline={deadline} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {layout === 'full' && (
                <>
                    <div className={`relative w-[300px] text-[16px] text-white mt-[36px] text-center`}>
                        {(currentAmount || currentAmount === 0) && needAmount ? (
                            <div className='relative'>
                                <p>
                                    Need{' '}
                                    <span className='text-[#FF3A3A]'>
                                        {defineCountString(needAmount - currentAmount) + ' '}
                                        {currentResource[offerType]}
                                    </span>{' '}
                                    to Proceed
                                </p>
                                <div className={`w-full flex justify-center items-center`}>
                                    <img className={`w-[35px] h-[35px]`} src={preloadResource[offerType]} alt='' />
                                    <div
                                        className={`relative w-full h-[20px] rounded-sm bg-[#452a31] border-solid border-[1px] border-black`}
                                    >
                                        <div
                                            style={{ width: `${(currentAmount! / needAmount!) * 100}%` }}
                                            className={`absolute left-0 h-full bg-[#3bdc96] rounded-sm`}
                                        ></div>
                                        <p className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-stroke-small '>
                                            {defineCountString(currentAmount)}/{defineCountString(needAmount)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className='flex flex-col gap-2 max-h-[250px] overflow-scroll '>
                            {/* BUTTONS ON THE FULLSCREEN OFFER */}
                            {battleBuilding && battleBuilding?.length === 1 && (
                                <div
                                    className={`w-full h-[72px] flex justify-between gap-2 p-[10px] items-center bg-[#463d36] sahdow-[inset_0_0_3px_white] rounded-md`}
                                >
                                    <div className={`min-w-[52px] w-[52px] min-h-[52px] h-[52px]`}>
                                        <img
                                            className={`w-full h-full`}
                                            src={require('../../../../assets/images/quests/11.png')}
                                            alt=''
                                        />
                                    </div>
                                    <div>
                                        <p className={`text-[14px] text-stroke-small text-left `}>Clear stages</p>
                                        <p className={`text-[12px] text-[#dfd9c3] text-left leading-tight`}>
                                            Go through the dungeon for a variety of rewards!
                                        </p>
                                    </div>
                                    <PopupButton
                                        type='blue'
                                        width='72px'
                                        onClick={() => {
                                            if (onEscape) {
                                                console.log('Escaping inside offer');
                                                onEscape();
                                            }
                                            if (onClose) {
                                                onClose();
                                            }
                                            dispatch(setSelectedRoom({ id: battleBuilding[0]?.id }));
                                            navigateTo('/island');
                                            setStartSelectHero(true);
                                        }}
                                    >
                                        Go
                                    </PopupButton>
                                </div>
                            )}
                            {offerType === OfferType.experience &&
                                buildings?.buildingConfigs?.filter((b) => b.resourceType === 2) &&
                                buildings?.buildingConfigs
                                    ?.filter((b) => b.resourceType === 2)
                                    .map((building) => (
                                        <div
                                            className={`w-full h-[72px] flex justify-between gap-2 p-[10px] items-center bg-[#463d36] sahdow-[inset_0_0_3px_white] rounded-md
                 ${getGatheredResources(building.id) ? '' : 'hidden'}`}
                                        >
                                            <div className={`min-w-[52px] w-[52px] min-h-[52px] h-[52px]`}>
                                                <img
                                                    className={`w-full h-full`}
                                                    src={require(
                                                        `../../../../assets/images/buildings/build${building.id}.png`
                                                    )}
                                                    alt=''
                                                />
                                            </div>
                                            <div>
                                                <p className={`text-[14px] text-stroke-small text-left `}>
                                                    Collect resources
                                                </p>
                                                <p className={`text-[12px] text-[#dfd9c3] text-left leading-tight`}>
                                                    Available resources: <br />{' '}
                                                    {getGatheredResources(building.id).toFixed(0)}{' '}
                                                </p>
                                            </div>
                                            <PopupButton
                                                type='blue'
                                                width='72px'
                                                onClick={() => {
                                                    if (onClose) {
                                                        onClose();
                                                    }
                                                    navigateTo('/island');
                                                }}
                                            >
                                                Go
                                            </PopupButton>
                                        </div>
                                    ))}
                            {offerType === OfferType.stoneOffer &&
                                buildings?.buildingConfigs?.filter((b) => b.resourceType === 1) &&
                                buildings?.buildingConfigs
                                    ?.filter((b) => b.resourceType === 1)
                                    .map((building) => (
                                        <div
                                            className={`w-full h-[72px] flex justify-between gap-2 p-[10px] items-center bg-[#463d36] sahdow-[inset_0_0_3px_white] rounded-md 
                ${getGatheredResources(building.id) ? '' : 'hidden'}`}
                                        >
                                            <div className={`min-w-[52px] w-[52px] min-h-[52px] h-[52px]`}>
                                                <img
                                                    className={`w-full h-full`}
                                                    src={require(
                                                        `../../../../assets/images/buildings/build${building.id}.png`
                                                    )}
                                                    alt=''
                                                />
                                            </div>
                                            <div>
                                                <p className={`text-[14px] text-stroke-small text-left `}>
                                                    Collect resources
                                                </p>
                                                <p className={`text-[12px] text-[#dfd9c3] text-left leading-tight`}>
                                                    Available resources: <br />{' '}
                                                    {getGatheredResources(building.id).toFixed(0)}
                                                </p>
                                            </div>
                                            <PopupButton
                                                type='blue'
                                                width='72px'
                                                onClick={() => {
                                                    if (onClose) {
                                                        onClose();
                                                    }
                                                    navigateTo('/island');
                                                }}
                                            >
                                                Go
                                            </PopupButton>
                                        </div>
                                    ))}
                            {offerType === OfferType.keysOffer ? (
                                <>
                                    {buildings?.buildingConfigs?.filter((b) => b.resourceType === 4) &&
                                        buildings?.buildingConfigs
                                            ?.filter((b) => b.resourceType === 4)
                                            .map((building) => (
                                                <div
                                                    className={`w-full h-[72px] flex justify-between gap-2 p-[10px] items-center bg-[#463d36] sahdow-[inset_0_0_3px_white] rounded-md 
                ${getGatheredResources(building.id) ? '' : 'hidden'}`}
                                                >
                                                    <div className={`min-w-[52px] w-[52px] min-h-[52px] h-[52px]`}>
                                                        <img
                                                            className={`w-full h-full`}
                                                            src={require(
                                                                `../../../../assets/images/buildings/build${building.id}.png`
                                                            )}
                                                            alt=''
                                                        />
                                                        ,
                                                    </div>
                                                    <div>
                                                        <p className={`text-[14px] text-stroke-small text-left `}>
                                                            Collect resources
                                                        </p>
                                                        <p
                                                            className={`text-[12px] text-[#dfd9c3] text-left leading-tight`}
                                                        >
                                                            Available resources: <br />{' '}
                                                            {getGatheredResources(building.id).toFixed(0)}
                                                        </p>
                                                    </div>
                                                    <PopupButton
                                                        type='blue'
                                                        width='72px'
                                                        onClick={() => {
                                                            if (onClose) {
                                                                onClose();
                                                            }
                                                            navigateTo('/island');
                                                        }}
                                                    >
                                                        Go
                                                    </PopupButton>
                                                </div>
                                            ))}

                                    <div
                                        className={`w-full h-[72px] flex justify-between gap-2 p-[10px] items-center bg-[#463d36] sahdow-[inset_0_0_3px_white] rounded-md`}
                                    >
                                        <div className={`min-w-[52px] w-[52px] min-h-[52px] h-[52px]`}>
                                            <img
                                                className={`w-full h-full`}
                                                src={resourcesMap[Resources.keys].image}
                                                alt=''
                                            />
                                        </div>
                                        <div>
                                            <p className={`text-[14px] text-stroke-small text-left `}>Buy Now</p>
                                            <p className={`text-[12px] text-[#dfd9c3] text-left leading-tight`}>
                                                Use Summon keys to get Heroes and Hero Shards
                                            </p>
                                        </div>
                                        <PopupButton
                                            type='gold'
                                            width='72px'
                                            onClick={() => {
                                                setOpenKitsuToKeySwap(true);
                                            }}
                                        >
                                            <div className='flex-col'>
                                                Buy
                                                <div className='flex justify-center items-center gap-1 pt-0.5'>
                                                    <div className='w-4 h-4 flex justify-center items-center'>
                                                        <img
                                                            className='w-full h-full'
                                                            src={resourcesMap[Resources.kitsu].image}
                                                            alt='icon'
                                                        />
                                                    </div>
                                                    {swapKitsuToKeyRate[0].swapRate.value}
                                                </div>
                                            </div>
                                        </PopupButton>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>

                    {openKitsuToKeySwap && (
                        <>
                            <ResourcePurchase
                                swapId={swapKitsuToKeyRate[0].id.value}
                                firstResourceType={ItemType.farmResource}
                                firstResourceID={Resources.kitsu}
                                secondResourceType={ItemType.farmResource}
                                secondResourceID={Resources.keys}
                                currentFirstResource={currentKitsu}
                                currentSecondResource={currentKeys}
                                swapRate={swapKitsuToKeyRate[0].swapRate.value}
                                onSwap={handleSwap}
                                onClose={() => setOpenKitsuToKeySwap(false)}
                                minBuy={swapKitsuToKeyRate[0].toMinAmount.value}
                            />
                        </>
                    )}

                    <SuccessOverlay
                        isSuccess={isSuccess}
                        onClose={() => {
                            if (onClose) onClose();
                        }}
                    />
                </>
            )}
        </div>
    );
};
